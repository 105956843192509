// config.js
const config = {
    app: {
        environment: "TEST",
        wordBountyDuration: 5,
        wordBountyTimeUnits: "minutes",
        wordBaronPriceLimit: 2.00,
        wordBountyVotePrice: 0.0149,
        lang: "en",
        lookAheadTimeout: 86400, //seconds
        showWhatsNew: true,
        verbiage: {
            support: "Support",
            leaderboard: "Leaderboards",
            login: "Login",
            logout: "Logout",
            walletLogin: "Login",
            wordBounty: "WORD BOUNTY",
            moneyButtonSearch: "SEEK",
            about: "About",
            ourPhilosophy: "Our Philosophy",
            words: "words",
            contactUs: "Contact Us",
            howItWorks: "How It Works",
            iCanDoBetter: "I can do better",
            lookUpWord: "Look up a word",
            smithingPage: {
                addAword: "Add a Word",
                beAwordsmith: "Be a Word$mith",
                word: "Word",
                hideFromchildren: "Hide From Children",
                slang: "Slang",
                uploadImage: "Upload Image",
                fromComputer: "From Computer",
                partOfspeech: "Part of Speech",
                pronunciation: "Pronunciation",
                definition: "Definition",
                exampleSentence: "Example Sentence",
                etymology: "Etymology",
                addWord: "Add Word"            
            },
            wordBountyPage: {
                watchOurTutorial: "Watch our Tutorial",
                payToDefineWords: "Dictionary investors, Word 'Barons', pay you to define words!",
                mustGetMostVotes: "Your definition entry must get the most votes to win.",
                termsOfService: "Terms of Service",
                tipsForWinning: "Tips for Winning!",
                activeBounties: "Active Bounties",
                completedBounties: "Completed Bounties"
            },
            SLebrityPage: {
                termsOfService: "About SLebrity Word Auctions"
            },
            SupportPage: {
                descriptionLabel: "Describe your issue in detail",
                categoryLabel: "Issue Type",
                emailLabel: "Email"
            }
        },
        SLebrity: {
            percentageIncrease: 20,
            skipPayment: false,
            showAuction: true,
            showNominations: true,
            showLeaderBoard: true
        },
        Seek: {
            showAuction: true
        }
    },
    handcash: {
        appId: "5fe2199e16c4ef0c59b984cc",
        appSecret: "948bab270ef79c6b8e98e74a96d50e86fd6f9697239976c54d5e96d8f8cb5801",
        paymentDestination: "SLictionary"
    },
    bsv: {
        opReturnKey: "19HxigV4QyBv3tHpQVcUEQyq1pzZVdoAut",
        satoshisPerBsv:  100000000,
        mapiKey: "mainnet_6ae6d243fb38ec8a69a1bd2748ab8efc"
    },
    cors: {
        allowedOrigins : [
            'http://localhost:3000',
            'https://slictionary-test-2b1f1.web.app',
            'https://www.slictionary.com'
       ]
    },
    url: {
        handcashService: "https://us-central1-slictionary-test-2b1f1.cloudfunctions.net/handcash",
        tokenService: "https://us-central1-slictionary-test-2b1f1.cloudfunctions.net/token",
        voteService: "https://us-central1-slictionary-test-2b1f1.cloudfunctions.net/vote",
        bsvService: "https://us-central1-slictionary-test-2b1f1.cloudfunctions.net/bsv",
        wordBountyService: "https://us-central1-slictionary-test-2b1f1.cloudfunctions.net/wordBounty",
        wordService: "https://us-central1-slictionary-test-2b1f1.cloudfunctions.net/word",
        setAllWbStatusToCompleted: "https://us-central1-slictionary-test-2b1f1.cloudfunctions.net/wordBounty/word/all/status/completed",
        wocMainAddress: "https://api.whatsonchain.com/v1/bsv/main/address",
        userService: "https://us-central1-slictionary-test-2b1f1.cloudfunctions.net/user"
    },
  }
  
  module.exports = config