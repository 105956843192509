import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
//import information from "../../assets/information-icon.png";
import information from "../../assets/Information_icon_flat.svg.png";

function SLicTooltip(props) {
  return (
    <>
        <OverlayTrigger 
          key="right"
          placement="right"
          overlay={
            <Tooltip id="tooltip-bio" >
                Daniel Krawisz
              {/* {props.bio} */}
            </Tooltip>
          }
        >
          <img src={information} style={{width: props.width, verticalAlign:props.verticalAlign}}/>
        </OverlayTrigger>
    </>
  );
}

export default SLicTooltip;
