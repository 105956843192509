import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

const settings = {};

const config = {
    apiKey: "AIzaSyCFZNcPK_HrJ1tDS7SNNtCCLw_MMEUlpJ0",
    authDomain: "slictionary-test-2b1f1.firebaseapp.com",
    databaseURL: "https://slictionary-test-2b1f1.firebaseio.com",
    projectId: "slictionary-test-2b1f1",
    storageBucket: "slictionary-test-2b1f1.appspot.com",
    messagingSenderId: "667847731081",
    appId: "1:667847731081:web:d2f4a79f42f1064efc6358"
  
};
firebase.initializeApp(config);

firebase.firestore().settings(settings);

const auth = firebase.auth();
auth.signInWithEmailAndPassword("johndifelice@protonmail.com", "google2940")
.then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
  
export default firebase;